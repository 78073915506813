import React from "react";
import { Folders } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  DataTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";
import { getTemplateKey } from "../../functions/workflowFunctions";

interface CardMatterTypeProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardMatterType: React.FC<CardMatterTypeProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  return (
    <Card
      image={
        <Folders
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="matterType"
      templateKey={getTemplateKey("matterType", region)}
      fileName="matterType.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "matterType",
      )}
      headerText={DataTypes.MatterTypes}
      description="Once Matters are valid, you can map your incoming Matter Types to Smokeball Matter Types."
      cardState={cardsState["matterType"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage="Matter Types can be configured once Matters are uploaded and valid."
    />
  );
};
