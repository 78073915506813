import styled from "@emotion/styled";
import { ReactNode } from "react";
import { TitleProps, Title } from "./title";
import { COLOURS } from "../shared";
import { ErrorBar } from "./errorBar";

export const Column = styled.div<{ slim?: boolean }>`
  position: relative;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: ${(props) => (props.slim ? props.theme.breakPoints.mobile : "100%")};
  max-width: 100%;
  margin: 0 auto;
`;

export const PageWrapper = styled.div`
  padding: 0rem;
  height: 100vh;
  overflow: hidden;
  background: ${COLOURS.pageContentWrapperBackround};
`;

export const ContentWrapper = styled.div<{ hasErrorBar?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${COLOURS.pageContentWrapperBackround};
  margin-top: ${(props) => (props.hasErrorBar ? "18.2rem" : "13.4rem")};
  overflow-y: auto;
`;

const FixedTitleWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  background: ${COLOURS.titleWrapperBackground}; /* Ensure the background color matches */
`;

export type PageProps = {
  titleProps: TitleProps;
  children: ReactNode;
  className?: string;
  errorBar?: { visible: boolean; message: string };
};

export const Page = ({ children, className, titleProps, errorBar }: PageProps) => (
  <PageWrapper className={className}>
    <Column>
      <FixedTitleWrapper>
        <Title {...titleProps} />
        {errorBar?.visible && <ErrorBar text={errorBar.message} />}
      </FixedTitleWrapper>
      <ContentWrapper hasErrorBar={errorBar?.visible}>{children}</ContentWrapper>
    </Column>
  </PageWrapper>
);
