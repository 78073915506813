import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Page, StyledIconButton, Tooltip } from "../../../components";
import { SummaryInfoModal } from "./summaryInfoModal";
import { NewMigration } from "./newMigration";
import { ExistingMigration } from "./existingMigration";
import { useExistingMigrations, useNewMigration } from "../../../hooks";
import { Plus } from "lucide-react";
import { useAuthContext } from "../authContext";
import ErrorScreen from "./authErrorScreen";
import { ImportTypeSelectionModal } from "./importTypeSelectionModal";
import { ImportOrExportTypeSelectionModal } from "./importOrExportTypeSelectionModal";
import { ExportTypeSelectionModal } from "./exportTypeSelectionModal";
import log from "../../../shared/utils/log";

const DisabledButtonWrapper = styled.div`
  float: right;
`;

const Dashboard = () => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportOrExportModal, setShowImportOrExportModal] = useState(false);
  const {
    handleNewMigrationClick,
    isLoading: isCreatingMigration,
    isUnauthorized: isNewMigrationUnauthorized,
  } = useNewMigration();
  const [migrationSetId, setMigrationSetId] = useState<number | null>(null);
  const {
    accountDetails,
    migrationSetWithImports,
    isLoading: isDataLoading,
    isUnauthorized: isExistingMigrationUnauthorized,
  } = useExistingMigrations();
  const { isAuthInitialized, isFirmOwnerOrInternalUser } = useAuthContext();
  const navigate = useNavigate();

  // Filter out 'smokeball-export' migrations if the user is not a firm owner/internal user
  const filteredMigrations = useMemo(() => {
    return (
      migrationSetWithImports?.filter(
        (migration) =>
          isFirmOwnerOrInternalUser ||
          migration.sourceSystem !== "smokeball-export",
      ) || []
    );
  }, [migrationSetWithImports, isFirmOwnerOrInternalUser]);

  // Determine if any migration is not complete
  const isAnyMigrationInProgress = useMemo(() => {
    return filteredMigrations.some(
      (migration) => migration.status !== "Complete" && migration.sourceSystem !== "smokeball-export",
    );
  }, [filteredMigrations]);

  const productId = accountDetails?.productId;

  if (
    isDataLoading ||
    !isAuthInitialized ||
    isAnyMigrationInProgress === undefined ||
    isCreatingMigration
  ) {
    return <Loader transparent={false} />;
  }

  if (
    isExistingMigrationUnauthorized ||
    isNewMigrationUnauthorized ||
    (isAuthInitialized && !productId)
  ) {
    return <ErrorScreen />;
  }

  // Function to handle the opening of the import selection modal
  const handleNewImportClick = () => {
    setShowImportModal(true);
  };

  // Function to handle the opening of the import/export modal
  const handleNewImportOrExportClick = () => {
    setShowImportOrExportModal(true);
  };

  // Handle confirmation from the modal
  const handleConfirm = async (importTypes: string[]) => {
    try {
      const migrationSetId = await handleNewMigrationClick(importTypes); // Call the hook to create migration

      // Ensure navigation happens after migration is created
      if (migrationSetId) {
        navigate(`/embedded/jumpstart/workflow/${migrationSetId}`); // Navigate after success
      } else {
        console.error("Migration creation failed");
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  return (
    <>
      <Page
        titleProps={{
          bottomRightContent: filteredMigrations.length ? (
            <DisabledButtonWrapper>
              <Tooltip
                enabled={isAnyMigrationInProgress && !isFirmOwnerOrInternalUser}
                message="An import is already in progress."
              >
                {isFirmOwnerOrInternalUser ? (
                  <StyledIconButton
                    disabled={false}
                    text="New import/export"
                    icon={<Plus size={20} className="icon" />}
                    onClick={async () => {
                      handleNewImportOrExportClick();
                    }}
                  />
                ) : (
                  <StyledIconButton
                    disabled={isAnyMigrationInProgress}
                    text="New import"
                    icon={<Plus size={20} className="icon" />}
                    onClick={async () => {
                      if (!isAnyMigrationInProgress) {
                        handleNewImportClick();
                      }
                    }}
                  />
                )}
              </Tooltip>
            </DisabledButtonWrapper>
          ) : undefined,
        }}
      >
        {isDataLoading ? ( // Show loader while loading
          <Loader transparent={false} />
        ) : filteredMigrations.length > 0 ? (
          <ExistingMigration
            data={filteredMigrations}
            selectMigration={async (id, status, sourceSystem) => {
              const selectedMigration = filteredMigrations.find(
                (m) => m.migrationSetId === id,
              );

              if (!selectedMigration) {
                console.error(`Migration with ID ${id} not found.`);
                return;
              }

              if (
                status === "Submitted" ||
                status === "Loading" ||
                status === "Complete"
              ) {
                setMigrationSetId(id);
              } else {
                navigate(`workflow/${id}`, {
                  state: { migrationData: selectedMigration },
                });
              }
            }}
          />
        ) : (
          <NewMigration handleConfirm={handleConfirm} productId={productId} />
        )}
        {!!migrationSetId && (
          <SummaryInfoModal
            migrationSetId={migrationSetId}
            onClose={() => setMigrationSetId(null)}
          />
        )}
      </Page>
      {showImportOrExportModal && (
        <ImportOrExportTypeSelectionModal
          closeModal={() => setShowImportOrExportModal(false)}
          onConfirm={(actionType) => {
            if (actionType === "import") {
              setShowImportModal(true);
            } else if (actionType === "export") {
              setShowExportModal(true);
            }
          }}
          isImportDisabled={isAnyMigrationInProgress}
        />
      )}
      {showImportModal && (
        <ImportTypeSelectionModal
          closeModal={() => setShowImportModal(false)}
          onConfirm={handleConfirm}
          productId={productId}
          titleText="New Import"
          subtitleText="Select items to include in your import"
          existingDataTypes={[]}
        />
      )}
      {showExportModal && (
        <ExportTypeSelectionModal
          closeModal={() => {
            setShowExportModal(false);
          }}
          onConfirm={(selectedOptions) => {
            log("Export confirmed:", selectedOptions);
            setShowExportModal(false);
          }}
          productId={productId}
          titleText="New Export"
          subtitleText="Select items to include in your export"
        />
      )}
    </>
  );
};

export { Dashboard };
