import { useState } from "react";
import { PageBox, Row, StyledIconButton } from "../../../components";
import styled from "@emotion/styled";
import { COLOURS } from "../../../shared";
import { Plus, Zap } from "lucide-react";
import { useAuthContext } from "../authContext";
import { ImportTypeSelectionModal } from "./importTypeSelectionModal";
import { ImportOrExportTypeSelectionModal } from "./importOrExportTypeSelectionModal";
import { ExportTypeSelectionModal } from "./exportTypeSelectionModal";
import log from "../../../shared/utils/log";

interface NewMigrationProps {
  handleConfirm: (importTypes: string[]) => void;
  productId: string | undefined;
}

export const NewMigration: React.FC<NewMigrationProps> = ({
  handleConfirm,
  productId,
}) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportOrExportModal, setShowImportOrExportModal] = useState(false);
  const { isFirmOwnerOrInternalUser } = useAuthContext();

  const IconWrapper = styled.div`
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background-color: ${COLOURS.newMigrationIconWrapperBackground};
    display: flex;
    justify-content: center;
    align-items: center;
  `;

  const StyledParagraph = styled.p`
    font-size: ${(props) => props.theme.fontSize.emphasis};
  `;

  // Function to handle the opening of the import selection modal
  const handleNewImportClick = () => {
    setShowImportModal(true);
  };

  // Function to handle the opening of the import/export modal
  const handleNewImportOrExportClick = () => {
    setShowImportOrExportModal(true);
  };

  return (
    <>
      <PageBox isExistingMigration={false} padding="6.4rem">
        <Row maxHeight={10}>
          <IconWrapper>
            <Zap
              color={COLOURS.newMigrationIconColor}
              strokeWidth={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </IconWrapper>
        </Row>
        <Row maxHeight={5}>
          <h1>
            Start your first import{isFirmOwnerOrInternalUser && "/export"}
          </h1>
        </Row>

        <Row>
          <StyledParagraph>
            Get a quick start to importing Contact and Matter data. You can
            start and stop this process at any time but once you have submitted
            an import it is irreversible.
            {isFirmOwnerOrInternalUser &&
              " Alternatively, you can also obtain a backup of the data in this firm."}
          </StyledParagraph>
        </Row>
        <Row maxHeight={20}>
          <StyledIconButton
            disabled={false}
            text={
              isFirmOwnerOrInternalUser ? "New import/export" : "New import"
            }
            icon={<Plus size={20} className="icon" />}
            onClick={
              isFirmOwnerOrInternalUser
                ? handleNewImportOrExportClick
                : handleNewImportClick
            }
          />
        </Row>
      </PageBox>

      {showImportOrExportModal && (
        <ImportOrExportTypeSelectionModal
          closeModal={() => setShowImportOrExportModal(false)}
          onConfirm={(actionType) => {
            if (actionType === "import") {
              setShowImportModal(true);
            } else if (actionType === "export") {
              setShowExportModal(true);
            }
          } } isImportDisabled={false}        />
      )}
      {showImportModal && (
        <ImportTypeSelectionModal
          closeModal={() => setShowImportModal(false)}
          onConfirm={handleConfirm}
          productId={productId}
          titleText="New Import"
          subtitleText="Select items to include in your import"
          existingDataTypes={[]}
        />
      )}
      {showExportModal && (
        <ExportTypeSelectionModal
          closeModal={() => {
            setShowExportModal(false);
          }}
          onConfirm={(selectedOptions: any) => {
            log("Export confirmed:", selectedOptions);
            setShowExportModal(false);
          }}
          productId={productId}
          titleText="New Export"
          subtitleText="Select items to include in your export"
        />
      )}
    </>
  );
};
