import styled from "@emotion/styled";

import {
  MigrationSetError,
  PageBox,
  Pill,
  Scheme,
  StandardButton,
  Table,
} from "../../../components";
import { COLOURS, MigrationSetWithImports } from "../../../shared";
import { useCallback, useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { formatUTCDateForLocale } from "../../../shared/utils/dateTimeFormatter";
import { useAuthContext } from "../authContext";

const OpenViewButton = styled(StandardButton)<{ status: string }>`
  color: ${COLOURS.openViewButtonColor};
  border: 1px solid
    ${(props) =>
      props.status === "Open Import"
        ? COLOURS.openButtonBorder
        : COLOURS.viewButtonBorder};
  background: ${(props) =>
    props.status === "Open Import"
      ? COLOURS.openButtonBackground
      : COLOURS.viewButtonBackground};
  width: 14.1rem;
`;

const DownloadButton = styled(StandardButton)<{ disabled: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: "auto";
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  width: 14.1rem;
`;

type ExistingMigrationProps = {
  data: MigrationSetWithImports[];
  selectMigration: (id: number, status: string, sourceSystem: string) => void;
  showNewImportButton?: boolean;
};

const ExistingMigration = ({
  data,
  selectMigration,
}: ExistingMigrationProps) => {
  const { isFirmOwnerOrInternalUser } = useAuthContext();
  
  // Precompute totalRecordCountForLoad for each migrationSet in data
  const totalRecordCountForLoadMap = useMemo(() => {
    return data.reduce(
      (acc, migrationSet) => {
        acc[migrationSet.migrationSetId] = migrationSet.imports.reduce(
          (sum, imp) => sum + imp.totalRecordCountForLoad,
          0,
        );
        return acc;
      },
      {} as Record<number, number>,
    );
  }, [data]);

  // Calculate loading percentage for each migrationSet using precomputed totalRecordCountForLoadMap
  const calculateLoadingPercentage = useCallback(
    (migrationSet: MigrationSetWithImports) => {
      const totalRecordCountForLoad =
        totalRecordCountForLoadMap[migrationSet.migrationSetId] || 0;

      // Calculate only totalRecordsLoaded, since totalRecordCountForLoad is precomputed
      const totalRecordsLoaded = migrationSet.imports.reduce(
        (sum, imp) => sum + (imp.loadTotalRecordCount + imp.loadErrorCount), // Errors are not included in the load total record and see need to be summed together
        0, // The initial value for the sum is set to 0.
      );

      // Calculate the percentage of loaded records relative to uploaded records.
      // If `totalRecordCountForLoad` is greater than 0, calculate the percentage (totalRecordsLoaded / totalRecordCountForLoad) * 100.
      // If `totalRecordCountForLoad` is 0 (to avoid division by 0), return 0%.
      const percentage =
        totalRecordCountForLoad > 0
          ? (totalRecordsLoaded / totalRecordCountForLoad) * 100
          : 0;

      // Cap percentage at 99% to have the UI show 99% to loading and then move to completed status
      return Math.min(Math.round(percentage), 99);
    },
    [totalRecordCountForLoadMap],
  );

  const cols = useMemo<ColumnDef<MigrationSetWithImports>[]>(
    () => [
      {
        header: "Type",
        accessorKey: "type",
        size: 6,
        cell: (item) => {
          const migrationSet = item.row.original;
          return migrationSet.sourceSystem === "smokeball-export" ? "Export" : "Import";        
        },
      },
      {
        header: "Submitted on",
        accessorKey: "utcSubmittedAt",
        sortType: "datetime",
        size: 16,
        cell: (item) =>
          formatUTCDateForLocale(
            item.row.original.utcSubmittedAt,
            item.row.original.accountBillingCountryCode,
          ),
      },
      {
        header: "Submitted by",
        accessorKey: "submittedByName",
        size: 17,
      },
      {
        header: "Information",
        accessorKey: "information",
        size: 31,
        cell: (item) => {
          const migrationSet = item.row.original;
          if (migrationSet.sourceSystem === "jumpstart") {
            switch (migrationSet.status) {
              case "In-progress":
              case "Submitted":
                return "Pending completion";
              case "Loading":
                const percentage = calculateLoadingPercentage(migrationSet);
                return `Pending completion - ${percentage}%`;
              case "Failed Validation":
              case "Complete":
                return <MigrationSetError migrationSet={migrationSet} />;
              default:
                return "Unknown status";
            }
          } else if (migrationSet.sourceSystem === "smokeball-export") {
            switch (migrationSet.status) {
              case "In-progress":
                return "Your data export has been requested";
              case "Submitted":
                return "Your data export has been submitted";
              case "Generating":
                return "Your data export is being generated";
              case "Failed":
                return "Unexpected error, please try again. If the issue persists, please contact Support via the help (?) button";
              case "Complete":
                return migrationSet.downloadURL ? (
                  <>
                    {migrationSet.downloadURLExpiresAt && (
                      <>
                        {"Export download link expires on "}
                        {formatUTCDateForLocale(
                          migrationSet.downloadURLExpiresAt,
                          migrationSet.accountBillingCountry,
                        )}
                      </>
                    )}
                  </>
                ) : (
                  "Export is complete but no download URL is available."
                );
              default:
                return "Unknown status";
            }
          }
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        size: 15,
        cell: (item) => {
          let statusValue = item.cell.getValue(); // Directly get the status value from the item
          let pillColor: Scheme;
          switch (statusValue) {
            case "In-progress":
            case "Loading":
            case "Generating":
              pillColor = "WARNING";
              break;
            case "Failed Validation":
            case "Failed":
            case "Expired":
              pillColor = "ERROR";
              break;
            case "Submitted":
              pillColor = "READY";
              break;
            case "Complete":
              const now = new Date();
              const exportFileURLexpiryDate = item.row.original
                .downloadURLExpiresAt
                ? new Date(item.row.original.downloadURLExpiresAt)
                : null;

              if (exportFileURLexpiryDate && now > exportFileURLexpiryDate) {
                pillColor = "ERROR";
                statusValue = "Expired";
              } else {
                pillColor = "SUCCESS";
                statusValue = "Complete";
              }
              break;
            default:
              pillColor = "ERROR";
          }
          return (
            <Pill
              lineHeight={1.5}
              borderRadius={16}
              fontSize={14}
              padding={"0.4rem 1.2rem"}
              text={`${statusValue}`}
              colorScheme={pillColor}
            />
          );
        },
      },
      {
        header: "",
        accessorKey: "openView",
        size: 15,
        cell: (item) => {
          let buttonText = "";
          const now = new Date();
          const isJumpstart = item.row.original.sourceSystem === "jumpstart";
          const isSmokeballExport =
            item.row.original.sourceSystem === "smokeball-export";

          // Check expiry for smokeball-export
          const expiryDate = item.row.original.downloadURLExpiresAt
            ? new Date(item.row.original.downloadURLExpiresAt)
            : null;

          const isDisabled =
            (expiryDate ? now > expiryDate : false) ||
            item.row.original.status === "Submitted" ||
            item.row.original.status === "In-progress" ||
            item.row.original.status === "Generating";

          if (isJumpstart) {
            buttonText =
              item.row.original.status === "Submitted" ||
              item.row.original.status === "Loading" ||
              item.row.original.status === "Complete"
                ? "View Summary"
                : "Open Import";
          } else if (isSmokeballExport) {
            buttonText = "Download";
          }

          return (
            <div style={{ display: "flex", gap: "1rem" }}>
              {/* Jumpstart Button */}
              {isJumpstart && (
                <OpenViewButton
                  onClick={() =>
                    selectMigration(
                      item.row.original.migrationSetId,
                      item.row.original.status,
                      item.row.original.sourceSystem,
                    )
                  }
                  status={buttonText}
                >
                  {buttonText}
                </OpenViewButton>
              )}

              {/* Smokeball Export Button */}
              {isSmokeballExport && (
                <DownloadButton
                  disabled={isDisabled}
                  onClick={() => {
                    if (!isDisabled) {
                      window.open(
                        item.row.original.downloadURL,
                        "_blank",
                        "noopener noreferrer",
                      );
                    }
                  }}
                >
                  {buttonText}
                </DownloadButton>
              )}
            </div>
          );
        },
      },
    ],
    [selectMigration, calculateLoadingPercentage],
  );

  const sortedData = useMemo(() => {
    return data
      .filter(migrationSet => {
        // Hide Smokeball Export rows if user is not a firm owner/internal user
        if (migrationSet.sourceSystem === "smokeball-export" && !isFirmOwnerOrInternalUser) {
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        // Item with null utcSubmittedAt should appear first
        if (a.utcSubmittedAt === null) return -1;
        if (b.utcSubmittedAt === null) return 1;
  
        // Sort by utcSubmittedAt in descending order
        const dateA = new Date(a.utcSubmittedAt!).getTime();
        const dateB = new Date(b.utcSubmittedAt!).getTime();
        return dateB - dateA;
      });
  }, [data, isFirmOwnerOrInternalUser]);  

  return (
    <>
      <PageBox isExistingMigration={true} padding="3.4rem 2.4rem">
        <Table data={sortedData} columns={cols} />
      </PageBox>
    </>
  );
};

export { ExistingMigration };
