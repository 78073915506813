export const formatUTCDateForLocale = (
    utcDateString: Date | null,
    accountBillingCountryCode: string,
  ): string => {
    if (!utcDateString) {
      return "";
    }
  
    const utcDate = new Date(utcDateString);
  
    const countryLocaleMap: Record<string, string> = {
      GB: "en-GB",
      AU: "en-AU",
      US: "en-US",
    };
  
    const locale = countryLocaleMap[accountBillingCountryCode];
  
    const formattedDate = utcDate.toLocaleDateString(locale, {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  
    const formattedTime = utcDate.toLocaleTimeString(locale, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  
    return `${formattedDate} - ${formattedTime}`;
  };
  