import React, { useState } from "react";
import {
  Checkbox,
  Loader,
  Modal,
  StandardButton,
  SubmitButton,
} from "../../../components";
import { COLOURS } from "../../../shared";
import { CircleHelp } from "lucide-react";
import log from "../../../shared/utils/log";

export const ImportOrExportTypeSelectionModal: React.FC<{
  closeModal: () => void;
  onConfirm: (actionType: string) => void;
  isImportDisabled: boolean;
}> = ({ closeModal, onConfirm, isImportDisabled }) => {
  const [selectedActionIndex, setSelectedActionIndex] = useState<number | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (index: number) => {
    log("Selected action index:", index);
    setSelectedActionIndex(index);
  };

  const handleGoBack = () => {
    log("Go back clicked");
    closeModal();
  };

  const handleSubmit = async () => {
    if (selectedActionIndex === null) {
      console.error("No option selected for import/export.");
      return;
    }

    setIsLoading(true);
    try {
      const actionType = selectedActionIndex === 0 ? "import" : "export";
      onConfirm(actionType);
    } catch (error) {
      console.error("Error during the process:", error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const isSubmitButtonDisabled = selectedActionIndex === null;

  return (
    <>
      <Modal
        backgroundColor={COLOURS.matterStateSelectionIconBackground}
        icon={
          <CircleHelp
            color={COLOURS.matterStateSelectionIconColor}
            strokeWidth={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
        buttons={[
          {
            Button: StandardButton,
            label: "Cancel, go back",
            action: handleGoBack,
          },
          {
            Button: ({ ...props }) => (
              <SubmitButton
                {...props}
                onClick={handleSubmit}
                disabled={isSubmitButtonDisabled}
                isSubmitButtonDisabled={isSubmitButtonDisabled}
              />
            ),
            label: "Continue",
            action: handleSubmit,
          },
        ]}
        headingText="Which action would you like to perform?"
      >
        <Checkbox
          id="dataImportActionType"
          name="selectedActionTypeOption"
          value="import"
          checked={selectedActionIndex === 0}
          onChange={() => !isImportDisabled && handleChange(0)}
          label={isImportDisabled ? "Data Import (import already in progress)" : "Data Import"}
          description="Import new data into this firm."
          index={0}
          type="radio"
          containerPadding="1rem 0"
          clickableContainer={!isImportDisabled}
          style={{
            cursor: isImportDisabled ? "not-allowed" : "pointer",
            opacity: isImportDisabled ? 0.5 : 1,
          }}
          disabled={isImportDisabled}
        />
        <Checkbox
          id="dataExportActionType"
          name="selectedActionTypeOption"
          value="export"
          checked={selectedActionIndex === 1}
          onChange={() => handleChange(1)}
          label="Data Export"
          description="Obtain a backup of the data in this firm. This process can take up to an hour depending on the size of your data."
          index={1}
          type="radio"
          containerPadding="1rem 0rem 2rem 0rem"
          clickableContainer={true}
        />
      </Modal>
      {isLoading && <Loader />}
    </>
  );
};
