import styled from "@emotion/styled";
import { COLOURS } from "../shared";

interface PageBoxProps {
  padding?: string;
}

export const PageBox = styled.div<
  { isExistingMigration: boolean } & PageBoxProps
>`
  display: flex;
  min-height: 35.3rem;
  max-height: 60vh;
  padding: ${(props) => props.padding};
  margin-top: 10rem;
  margin-bottom: 12rem;
  flex-direction: column;
  ${({ isExistingMigration }) =>
    isExistingMigration
      ? "align-items: flex-start; justify-content: full; margin-left: 8rem; margin-right: 8rem; max-width: calc(100% - 16rem);"
      : "align-items: center; justify-content: center; margin-left: 16rem; margin-right: 16rem; max-width: calc(100% - 32rem);"}
  align-self: stretch;
  border-radius: 0.8rem;
  background: ${COLOURS.pageBoxBackground};
  box-shadow:
    0px 1px 2px -1px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 1511px) {
    margin-left: 8rem;
    margin-right: 8rem;
    max-width: calc(100% - 16rem);
  }

  h1 {
    color: ${COLOURS.pageBoxHeading1Color};
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.heading};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  p {
    align-self: stretch;
    color: ${COLOURS.pageBoxParagraphColor};
    text-align: center;
    font-size: ${(props) => props.theme.fontSize.emphasis};
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
`;
