import styled from "@emotion/styled";
import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";

import { ButtonProps, activeButtonColor, buttonColor } from "./utils";
import { IconName } from "../../icons";
import { COLOURS } from "../../shared";

const Button = styled.button<ButtonProps>`
  padding: 0.625rem 1.125rem;
  border: 0;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${activeButtonColor};
  color: ${buttonColor};

  .text {
    margin-left: ${(props) => props.theme.baseUnit}rem;
    margin-right: 0.4rem;
    font-size: ${(props) => props.theme.fontSize.base};
    font-weight: 600;
  }
`;

type BaseIconButtonProps = {
  icon?: ReactNode;
  name?: IconName;
  onClick?: () => void;
  onPress?: () => void;
  className?: string;
  text?: string;
  size?: number;
  rotate?: number;
  spin?: boolean;
  spinDirection?: "cw" | "ccw";
  rightIcon?: { name: IconName; size?: number };
  transformSVG?: string;
};

export type IconButtonProps = BaseIconButtonProps &
  ButtonProps &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export type DefaultIconButtonProps = Omit<BaseIconButtonProps, "onClick"> &
  ButtonProps &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const IconButton = ({
  icon,
  name,
  text,
  size,
  spin,
  spinDirection,
  rotate,
  rightIcon,
  transformSVG,
  onClick,
  ...props
}: IconButtonProps | DefaultIconButtonProps) => (
  <Button type="button" onClick={onClick} {...props}>
    {text && <span className="text">{text}</span>}
    {icon}
  </Button>
);

const StyledIconButton = styled(IconButton)`
  height: 4rem;
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 600;
  color: ${COLOURS.styledIconColor};
  background-color: ${COLOURS.primaryButtonBackground};
  border: 2px solid ${COLOURS.primaryButtonBorder};
  border-radius: 0.5rem;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;

  .text {
    order: 1;
  }

  .icon {
    order: 2;
    margin-right: 0.625rem;
  }
`;

export { IconButton, StyledIconButton };
