import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import ReactModal from "react-modal";
import styled from "@emotion/styled";
import { X, Zap } from "lucide-react";
import {
  Checkbox,
  Loader,
  StandardButton,
  SubmitButton,
} from "../../../components";
import { COLOURS, DataTypes, useSmokeballApp } from "../../../shared";
import { getRegionFromURL } from "../../../shared/utils/region";
import {
  getTimeFeesDisbursementsRegionSpecificLabel,
  PRODUCT_TIERS,
} from "../../../shared/utils/constants";
import log from "../../../shared/utils/log";
import { useNewMigration, useSubmitMigration } from "../../../hooks";
import { useNavigate } from "react-router-dom";

const StyledModal = styled.div`
  width: 56rem;
  max-height: 75.7rem;
  border-radius: 0.8rem;
  background-color: ${COLOURS.importAndExportModalContainerBackground};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  // Media query for screens below 950px in height
  @media (max-height: 950px) {
    max-height: 65rem;
  }

  // Media query for screens below 850px in height
  @media (max-height: 850px) {
    max-height: 55rem;
  }

  // Media query for screens below 750px in height
  @media (max-height: 750px) {
    max-height: 50rem;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 7.5rem;
  margin-left: 2.4rem;
  margin-right: 2.4rem;
`;

const IconWrapper = styled.div`
  background-color: ${COLOURS.newMigrationIconWrapperBackground};
  padding: 1.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
  margin-top: 1.6rem;
`;

// Title styling
const Title = styled.h2`
  color: ${COLOURS.importAndExportModalHeadingColor};
  font-size: ${(props) => props.theme.fontSize.subHeading};
  font-weight: 600;
  margin: 0;
`;

const Subtitle = styled.p`
  color: ${COLOURS.importAndExportModalHeadingColor};
  font-size: ${(props) => props.theme.fontSize.base};
  font-weight: 500;
  margin-top: 0.4rem;
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
`;

// Divider & Other Containers
const Divider = styled.hr`
  border-top: 1px solid ${COLOURS.importAndExportModalDivider};
  margin: 0;
`;

const OptionsContainer = styled.div`
  max-height: 58.8rem;
  width: 56rem;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2.4rem 2.4rem 0.8rem 2.4rem;
`;

const OptionRow = styled.div<{ selected: boolean }>`
  margin-bottom: 0.95rem;
  border: 1px solid ${COLOURS.importAndExportModalDataTypeBorder};
  border-radius: 0.8rem;
  align-items: center;
  justify-content: center;
  padding: 0.7rem;
  user-select: none;
  background-color: ${({ selected }) =>
    selected
      ? COLOURS.importAndExportModalDataTypeSelectedBackground
      : COLOURS.importAndExportModalContainerBackground};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.4rem;
  height: 9.2rem;
`;

export interface ExportOption {
  label: string;
  description?: string;
  dependentOn?: string;
  exportTypes: string[];
  selected: boolean;
}

export const ExportTypeSelectionModal: React.FC<{
  closeModal: () => void;
  onConfirm: (selectedOptions: string[]) => void;
  productId: string | undefined;
  titleText: string;
  subtitleText: string;
}> = ({ closeModal, onConfirm, productId, titleText, subtitleText }) => {
  const navigate = useNavigate();          
  const region = getRegionFromURL();
  const theme = useTheme();
  const [exportTypesToShow, setDataTypesToShow] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { auth, context } = useSmokeballApp();

  const { handleNewMigrationClick, isLoading: isInitialising } =
    useNewMigration();
  const { handleSubmitMigrationClick, isLoading: isSubmitting } =
    useSubmitMigration({
      accountId: context?.firmId || "",
      token: auth?.token,
    });

  // Matter relationships always needs to be associated with matters but should only be shown for Grow or Prosper clients
  const isGrowOrProsper =
    productId === PRODUCT_TIERS.GROW || productId === PRODUCT_TIERS.PROSPER;

  const [options, setOptions] = useState<ExportOption[]>(() => {
    // Initialise options with region and tier logic
    let initialOptions: ExportOption[] = [
      {
        label: DataTypes.Staff,
        selected: false,
        exportTypes: [DataTypes.Staff],
      },
      {
        label: DataTypes.Contacts,
        selected: false,
        exportTypes: [DataTypes.Contacts],
      },
      {
        label: DataTypes.Matters,
        selected: false,
        exportTypes: [
          DataTypes.Matters,
          ...(isGrowOrProsper ? [DataTypes.MatterRelationships] : []),
        ],
      },
      // {
      //   label: DataTypes.OpeningBalances,
      //   selected: false,
      //   exportTypes: [DataTypes.OpeningBalances],
      // },
      // {
      //   label: getTimeFeesDisbursementsRegionSpecificLabel(region),
      //   selected: false,
      //   exportTypes: [
      //     DataTypes.ActivityTypes,
      //     DataTypes.UnbilledTimeAndFees,
      //     DataTypes.UnbilledDisbursements,
      //   ],
      // },
      // {
      //   label: DataTypes.Invoices,
      //   selected: false,
      //   exportTypes: [DataTypes.Invoices],
      // },
    ];

    // Check for Boost, Grow, and Prosper product tiers
    if (
      productId === PRODUCT_TIERS.BOOST ||
      productId === PRODUCT_TIERS.GROW ||
      productId === PRODUCT_TIERS.PROSPER
    ) {
      initialOptions.push(
        // {
        //   label: DataTypes.Memos,
        //   selected: false,
        //   exportTypes: [DataTypes.Memos],
        // },
        {
          label: DataTypes.Tasks,
          selected: false,
          exportTypes: [DataTypes.Tasks],
        },
      );
    }

    // // Add Safe Custody Packets and Documents only for Grow/Prosper tiers and non-US regions
    // if (
    //   (productId === PRODUCT_TIERS.GROW ||
    //     productId === PRODUCT_TIERS.PROSPER) &&
    //   region !== "US"
    // ) {
    //   initialOptions.push({
    //     label: "Safe Custody Packets and Documents",
    //     selected: false,
    //     exportTypes: [
    //       DataTypes.SafeCustodyPackets,
    //       DataTypes.SafeCustodyDocuments,
    //     ],
    //   });
    // }

    return initialOptions;
  });

  const handleOptionChange = (index: number) => {
    const newOptions = [...options];
    newOptions[index].selected = !newOptions[index].selected;

    const selectedOption = newOptions[index];

    // If the option is selected, add its cards to the list
    if (selectedOption.selected) {
      setDataTypesToShow((prevDataTypes) => {
        const newDataTypes = [...prevDataTypes];
        selectedOption.exportTypes.forEach((exportType) => {
          if (!newDataTypes.includes(exportType)) {
            newDataTypes.push(exportType); // Add the card if it's not already in the list
          }
        });
        return newDataTypes;
      });
    } else {
      // If the option is deselected, check if any other selected options require this option's export types
      setDataTypesToShow((prevDataTypes) => {
        const newDataTypes = prevDataTypes.filter(
          (exportType) =>
            !selectedOption.exportTypes.includes(exportType) ||
            // Keep export types that are still required by other selected options
            newOptions.some(
              (option) =>
                option.selected && option.exportTypes.includes(exportType),
            ),
        );
        return newDataTypes;
      });
    }
    setOptions(newOptions);
  };

  const handleUnselectAll = () => {
    setOptions(options.map((option) => ({ ...option, selected: false })));
    setDataTypesToShow([]); // Clear export types when unselecting all
  };

  const handleSelectAll = () => {
    // Always show these export types for all tiers
    const defaultDataTypes = [
      DataTypes.Staff,
      DataTypes.Contacts,
      DataTypes.Matters,
      ...(isGrowOrProsper ? [DataTypes.MatterRelationships] : []),
      // DataTypes.OpeningBalances,
      // DataTypes.ActivityTypes,
      // DataTypes.UnbilledTimeAndFees,
      // DataTypes.UnbilledDisbursements,
      // DataTypes.Invoices,
    ];

    exportTypesToShow.push(...defaultDataTypes);

    // Add region and tier specific exports
    if (
      productId === PRODUCT_TIERS.BOOST ||
      productId === PRODUCT_TIERS.GROW ||
      productId === PRODUCT_TIERS.PROSPER
    ) {
      exportTypesToShow.push(DataTypes.Tasks);
//      exportTypesToShow.push(DataTypes.Memos, DataTypes.Tasks);
    }

    // if (
    //   (productId === PRODUCT_TIERS.GROW ||
    //     productId === PRODUCT_TIERS.PROSPER) &&
    //   region !== "US"
    // ) {
    //   exportTypesToShow.push(
    //     DataTypes.SafeCustodyPackets,
    //     DataTypes.SafeCustodyDocuments,
    //   );
    // }

    setOptions(options.map((option) => ({ ...option, selected: true })));
    setDataTypesToShow(exportTypesToShow);
  };

  const handleProceed = async () => {
    setIsLoading(true);
    log("Export Initialising:", isInitialising);
    
    try {
      // Ensure selected types are unique
      const uniqueDataTypes = [...new Set(exportTypesToShow)];
      log("Final unique export types: ", uniqueDataTypes);
  
      // Initialise the migration, passing "smokeball-export" as the source system
      const sourceSystem = "smokeball-export";
      const migrationSetId = await handleNewMigrationClick(uniqueDataTypes, sourceSystem);
  
      if (!migrationSetId) {
        console.error("Failed to initialize export migration.");
        return;
      }
  
      log("Export Migration Initialised with ID:", migrationSetId);
  
      // Immediately submit the migration for export processing
      await handleSubmitMigrationClick(migrationSetId.toString());
      closeModal();
      log("Export is being submitted: ", isSubmitting);      
      // Navigate back to main page
      navigate("/embedded/jumpstart");
  
    } catch (error) {
      console.error("Error during export process:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const allSelected = options.every((option) => option.selected); // Check if all options are selected
  const selectedCount = options.filter((option) => option.selected).length;

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      style={{
        overlay: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.25)",
          padding: 0,
        },
        content: {
          position: "relative",
          borderRadius: "1.2rem",
          margin: "0rem auto",
          padding: 0,
        },
      }}
    >
      <StyledModal>
        {/* Modal Header */}
        <Header>
          <IconWrapper>
            <Zap
              color={COLOURS.newMigrationIconColor}
              strokeWidth={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </IconWrapper>
          <TitleContainer>
            <Title>{titleText}</Title>
            <Subtitle>{subtitleText}</Subtitle>
          </TitleContainer>
          <CloseButtonWrapper onClick={closeModal}>
            <X
              size={18}
              strokeWidth={2}
              color={COLOURS.importAndExportModalCloseButton}
            />
          </CloseButtonWrapper>
        </Header>

        {/* Dividing Line Below Heading */}
        <Divider />

        {/* Options List */}
        <OptionsContainer>
          {options.map((option, index) => (
            <OptionRow
              key={index}
              selected={option.selected}
              onClick={() => handleOptionChange(index)}
            >
              <Checkbox
                id={`option-${index}`}
                name="exportOption"
                value={option.label}
                checked={option.selected}
                onChange={() => handleOptionChange(index)}
                label={option.label}
                index={0}
                type="checkbox"
                labelFontSize={theme.fontSize.base}
                labelColor={COLOURS.importAndExportModalDataTypeHeadingColor}
                descriptionFontSize={theme.fontSize.small}
                descriptionColor={
                  COLOURS.importAndExportModalDataTypeParagraphColor
                }
                shouldStopPropagation={true}
              />
            </OptionRow>
          ))}
        </OptionsContainer>

        {/* Dividing Line Between Options and Buttons */}
        <Divider />

        {/* Modal Footer with Buttons */}
        <Footer>
          <StandardButton
            onClick={allSelected ? handleUnselectAll : handleSelectAll} // Toggle between select/unselect all
            fontSize={theme.fontSize.emphasis}
            width="calc(50% - 0.6rem)"
            height="4.4rem"
            padding="1.2rem 2.0rem"
          >
            {allSelected ? "Unselect All" : "Select All"}
          </StandardButton>
          <SubmitButton
            onClick={handleProceed}
            disabled={selectedCount === 0}
            isSubmitButtonDisabled={selectedCount === 0}
            disableButtonAccess={false}
            fontSize={theme.fontSize.emphasis}
            width="calc(50% - 0.6rem)"
            height="4.4rem"
            padding="1.2rem 2.0rem"
          >
            {`${selectedCount} Selected, Proceed`}
          </SubmitButton>
        </Footer>
      </StyledModal>
      {isLoading && <Loader />}
    </ReactModal>
  );
};
