import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { useSmokeballApp } from "../../shared";
import { extractCognitoToken } from "../../shared/utils";
import log from "../../shared/utils/log";

interface AuthContextProps {
  productId: string | null;
  isAuthInitialized: boolean;
  isFirmOwnerOrInternalUser: boolean;
  tokenError: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextProps>({
  productId: null,
  isAuthInitialized: false,
  isFirmOwnerOrInternalUser: false,
  tokenError: false,
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { auth } = useSmokeballApp();
  const [productId, setProductId] = useState<string | null>(null);
  const [isFirmOwnerOrInternalUser, setIsFirmOwnerOrInternalUser] = useState<boolean>(false);
  const [isAuthInitialized, setIsAuthInitialized] = useState<boolean>(false);
  const [tokenError, setTokenError] = useState<boolean>(false);

  useEffect(() => {
    const fetchProductId = async (): Promise<void> => {
      try {
        if (!auth) {
          log("Auth object is not initialized");
          return;
        }
        const token = await auth.token();
        if (!token) {
          log("Token is not available");
          setTokenError(true);
        }
        log("Token:", { token });
        const decoded = extractCognitoToken(token);
        const productIdFromToken = (decoded as any)[
          "custom:smokeball:productId"
        ];
        setProductId(productIdFromToken || null);
        setIsAuthInitialized(true);
        const roles = (decoded as any)["custom:smokeball:roles"];
        if (roles.includes("FirmAdministrator") || roles.includes("InternalUser")) {
          setIsFirmOwnerOrInternalUser(true);
        }
      } catch (error) {
        log("Error fetching Product ID:", { error });
      }
    };

    fetchProductId();
  }, [auth]);

  return (
    <AuthContext.Provider
      value={{ productId, isAuthInitialized, tokenError, isFirmOwnerOrInternalUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
