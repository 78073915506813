import React from "react";
import { BookOpenCheck } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  DataTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";
import { getMatterOpeningBalancesDescription, getTemplateKey } from "../../functions/workflowFunctions";
import { CardDescription } from "../cardDescription";

interface CardMatterOpeningBalanceProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardMatterOpeningBalance: React.FC<
  CardMatterOpeningBalanceProps
> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
  region,
}) => {
  const matterOpeningBalanceImport = data?.imports.find((i) => i.importType === "matterOpeningBalance");

  return (
    <Card
      image={
        <BookOpenCheck
          size={60}
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.625rem",
          }}
        />
      }
      importType="matterOpeningBalance"
      templateKey={getTemplateKey("matterOpeningBalance", region)}
      fileName="matterOpeningBalance.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find(
        (i) => i.importType === "matterOpeningBalance",
      )}
      headerText={DataTypes.OpeningBalances}
      description={
        <CardDescription
          {...getMatterOpeningBalancesDescription(region, matterOpeningBalanceImport)}
          displayWiderColumn={true} // Pass the displayWiderColumn flag prop as true for all opening balances
        />
      }      
      cardState={cardsState["matterOpeningBalance"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
      tooltipMessage="Opening Balances can only be uploaded once Matters are uploaded and valid."
    />
  );
};
