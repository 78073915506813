
export const pluralWords = Object.freeze({
  // card types
  STAFF: "Staff",
  ENTRY: "Entries",
});

export const singleWords = Object.freeze({
  // card types
  STAFF: "Staff",
  ENTRY: "Entry",
}); 

// Function to get region-specific expense header
export const getRegionSpecificExpenseHeader = (region: string): string => {
  switch (region) {
    case "AU":
      return "Unbilled Disbursements";
    case "GB":
      return "Unbilled Cost Recoveries";
    case "US":
      return "Unbilled Expenses";
    default:
      return "Unbilled Expenses"; // default to US if region is not recognised
  }
};

// Function to get region-specific summary expense header
export const getRegionSpecificSummaryExpenseHeader = (region: string): string => {
  switch (region) {
    case "AU":
      return "Disbursements";
    case "GB":
      return "Cost Recoveries";
    case "US":
      return "Expenses";
    default:
      return "Expenses"; // default to US if region is not recognised
  }
};

// Function to get region-specific data selection modal time, fee, and disb label
export const getTimeFeesDisbursementsRegionSpecificLabel = (region: string) => {
  switch (region) {
    case "AU":
      return "Unbilled Time, Fees, and Disbursements";
    case "US":
      return "Unbilled Time, Fees, and Expenses";
    case "GB":
      return "Unbilled Time, Fees, and Cost Recoveries";
    default:
      return "Unbilled Time, Fees, and Expenses"; // Default to US wording
  }
};

// Function to get region-specific data selection opening balances description
export const getOpeningBalancesSpecificDescription = (region: string) => {
  switch (region) {
    case "AU":
      return "Import Credit and Trust balances";
    case "US":
      return "Import Operating, Credit, and Trust balances";
    case "GB":
      return "Import Credit and Client account balances";
    default:
      return "Import Operating, Credit, and Trust balances";
  }
};

export const PRODUCT_TIERS = Object.freeze({
  BILL: "SMK001",
  BOOST: "SMK004",
  GROW: "SMK002",
  PROSPER: "SMK003",
});