import React from "react";
import { ListTodo } from "lucide-react";
import { QueryObserverResult } from "react-query";
import {
  COLOURS,
  DataTypes,
  MigrationSetImport,
  MigrationSetWithImports,
} from "../../shared";
import { Card } from "../card";

interface CardTaskProps {
  currentMigrationSetId: number;
  data: { imports: MigrationSetImport[] } | undefined;
  cardsState: Record<string, any>;
  disableButtonAccess: boolean;
  refetchMigrationSet: () => Promise<
    QueryObserverResult<MigrationSetWithImports, unknown>
  >;
  region: string;
}

export const CardTask: React.FC<CardTaskProps> = ({
  currentMigrationSetId,
  data,
  cardsState,
  disableButtonAccess,
  refetchMigrationSet,
}) => {
  return (
    <Card
      image={
        <ListTodo
          color={COLOURS.cardIconColor}
          strokeWidth={2}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      }
      importType="task"
      templateKey="taskImport"
      fileName="task.csv"
      migrationSetId={currentMigrationSetId}
      migrationSetImport={data?.imports.find((i) => i.importType === "task")}
      headerText={DataTypes.Tasks}
      description="Imported Tasks can optionally be associated with a Matter. If this is the case for any of your Tasks, a Matters file must also be uploaded."
      cardState={cardsState["task"]}
      disableButtonAccess={disableButtonAccess}
      refetchMigrationSet={refetchMigrationSet}
    />
  );
};
