import { Dispatch, SetStateAction, useRef } from "react";
import { useMutation } from "react-query";
import {
  ImportType,
  MigrationSetImport,
  MigrationSetImportWithUploadLink,
  TemplateKey,
  useSmokeballApp,
} from "../shared";
// import { useWhyDidYouUpdate } from '../shared/hooks';
import oneschemaImporter, { OneSchemaImporterClass } from "@oneschema/importer";
import { useCancelOneSchemaSession } from "./cancelOneSchemaUpload";
import { useClearMigrationDataTypeUpload } from "./clearMigrationDataTypeUpload";
import { CompletionOfMigrationOptions } from "./completeMigrationDataTypeUpload";
import { useCallback, useEffect } from "react";
import log from "../shared/utils/log";
import { requestHelper } from "../shared/utils";

export interface ImporterInitialisationParams {
  importType: ImportType;
  accountId: string;
  templateKey: TemplateKey;
  fileName: string;
  migrationSetId: number;
  hasMattersInMultipleStates: boolean;
  migrationSetImport?: MigrationSetImport;
  handleCompletedData: (options: CompletionOfMigrationOptions) => Promise<void>;
}

const fetchPresignedURLAndUserJwtOrOneSchemaSession = async ({
  accountId,
  fileName,
  importType,
  hasMattersInMultipleStates,
  migrationSetId,
  templateKey,
  migrationSetImport,
  token,
}: {
  accountId: string;
  fileName: string;
  importType: string;
  hasMattersInMultipleStates?: boolean;
  migrationSetId: number;
  templateKey: string;
  migrationSetImport?: MigrationSetImport;
  token?: () => Promise<string>;
}): Promise<MigrationSetImportWithUploadLink> => {
  let endpoint = `/${migrationSetId}`;
  let errorMessage = "Failed to retrieve ";

  // Check if we should resume or start a new session
  if (!migrationSetImport || (migrationSetImport.uploadInProgress && !migrationSetImport.hasUploaded)) {
    // If there is no migrationSetImport or uploadInProgress is true but nothing has been uploaded, start a new session
    endpoint += `/${importType}/start?template=${templateKey}&fileName=${fileName}`;

    if (importType === "matter" && hasMattersInMultipleStates === true) {
      endpoint += `&hasMattersInMultipleStates=${hasMattersInMultipleStates}`;
    }

    errorMessage += "userJWT and presignedURL.";
  } else {
    endpoint += `/${migrationSetImport.migrationImportId}/resume`;
    errorMessage += "session token.";
  }

  log("Endpoint being called: ", { endpoint });

  const data = await requestHelper(
    accountId,
    endpoint,
    errorMessage,
    "POST",    
    token,
  );
  log("Data for import set import with upload link or session token: ", data);

  return data;
};
interface UseOneSchemaHookProps {
  refetchMigrationSet: () => Promise<any>;
  setIsOneSchemaLoading: Dispatch<SetStateAction<boolean>>;
}

export const useOpenOneSchemaSession = ({
  refetchMigrationSet,
  setIsOneSchemaLoading,
}: UseOneSchemaHookProps) => {
  const { auth } = useSmokeballApp();
  const { mutateAsync: fetchImportWithUploadLink } = useMutation(
    fetchPresignedURLAndUserJwtOrOneSchemaSession
  );
  const { handleCancelOnceSchemaSessionClick } = useCancelOneSchemaSession();
  const { handleClearMigrationDataTypeClick } =
    useClearMigrationDataTypeUpload();
    const importer = useRef<OneSchemaImporterClass | null>(null);
  const migrationSetImport = useRef<MigrationSetImportWithUploadLink | null>(
    null
  );

  // useWhyDidYouUpdate('useOpenOneSchemaSession', {
  //   token: auth?.token,
  //   handleCancelOnceSchemaSessionClick,
  //   handleClearMigrationDataTypeClick,
  //   refetchMigrationSet,
  //   setIsOneSchemaLoading,
  // });

  const onCancel = useCallback(
    async (evt?: BeforeUnloadEvent) => {
      log("Data at the time of cancel: ", migrationSetImport.current);
      log("Importer cancelled.");
      if (!migrationSetImport.current) {
        setIsOneSchemaLoading(false);
        return;
      }
      evt?.preventDefault?.(); // if triggered from the beforeunload event listener
      if (migrationSetImport.current.hasUploaded) {
        log("Calling cancel session.");
        const { accountId, migrationSetId, migrationImportId } =
          migrationSetImport.current;
        await handleCancelOnceSchemaSessionClick({
          accountId,
          migrationSetId,
          migrationImportId,
          token: auth?.token,
        }).catch((error) => console.error("Error cancelling session:", error));
      } else {
        log("Calling clear session.");
        await handleClearMigrationDataTypeClick({
          migrationSetImport: migrationSetImport.current,
          token: auth?.token,
        }).catch((error) => console.error("Error cancelling session:", error));
      }
      await refetchMigrationSet();
      setIsOneSchemaLoading(false);
      importer.current!.close(true);
      migrationSetImport.current = null;
    },
    [
      auth?.token,
      handleCancelOnceSchemaSessionClick,
      handleClearMigrationDataTypeClick,
      refetchMigrationSet,
      setIsOneSchemaLoading,
    ]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", onCancel);

    return () => {
      importer.current?.removeAllListeners?.();
      window.removeEventListener("beforeunload", onCancel);
      if (importer.current && migrationSetImport.current) {
        onCancel();
      }
    };
  }, [onCancel]);

  return useCallback(
    async (uploadParameters: ImporterInitialisationParams) => {
      // So this is called from the `handleUploadConfigureRevalidateClick` function,
      // Which is essentially when a user clicks Edit/Upload/Re-validate
      setIsOneSchemaLoading(true);

      try {
        // Get the pre-signed URL and jwt for OneSchema to use
        const importWithUploadLink = await fetchImportWithUploadLink({
          ...uploadParameters,
          token: auth?.token,
        });
        migrationSetImport.current = importWithUploadLink;

        log("SUCCESS DATA useUpload:", { importWithUploadLink });
        const {
          accountId,
          migrationImportId,
          migrationSetId,
          sessionToken,
          templateKey,
          uploadDetails,
        } = importWithUploadLink;
        const presignedURL = uploadDetails?.presignedURL; // will not be present if resuming in-progress upload, as there is no need to upload the file again
        const userJwt = uploadDetails?.userJwt; // will not be present if resuming in-progress upload, as there is no need to upload the file again

        const clientId =
          process.env.REACT_APP_MIG_JUMPSTART_ONE_SCHEMA_CLIENT_ID ?? "";
        const baseUrlOneSchema =
          process.env.REACT_APP_MIG_JUMPSTART_ONE_SCHEMA_BASE_URL;
        log("Base URL schema: ", baseUrlOneSchema);

        importer.current = oneschemaImporter({
          clientId,
          baseUrl: baseUrlOneSchema,
          parentId: "migration-app-root",
          ...(sessionToken
            ? { sessionToken }
            : {
                templateKey,
                userJwt,
                importConfig: {
                  type: "file-upload",
                  url: presignedURL,
                  format: "csv",
                },
              }),
          devMode: false,
          className: "oneschema-importer",
          styles: {
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            zIndex: "10000",
          },
        });

        importer.current.launch();

        importer.current.on("success", async (importData) => {
          log("Success: ", importData);
          log("Success error count: ", importData.error_count);
          log("Success total row count: ", importData.count);
          log("Embed ID: ", importData.embed_id);

          const completionOptions = {
            accountId,
            migrationImportId,
            migrationSetId,
            totalRowCount: importData.count,
            embedId: importData.embed_id,
            token: auth?.token,
          };

          await uploadParameters.handleCompletedData(completionOptions);
          await refetchMigrationSet();
          setIsOneSchemaLoading(false);

          // TODO: need to 'unlisten' to all the events? Look at the OneSchema React.
          importer.current!.close(true);
          migrationSetImport.current = null;
        });

        importer.current.on("cancel", onCancel);

        importer.current.on("error", (error: any) => {
          console.error("Error using importer: ", { error });
          setIsOneSchemaLoading(false);
          migrationSetImport.current = null;
        });

        // Nothing needs to be returned
      } catch (error) {
        console.error("Initialisation failed: ", error);
        setIsOneSchemaLoading(false);
        migrationSetImport.current = null;
      }
    },
    [
      fetchImportWithUploadLink,
      setIsOneSchemaLoading,
      auth?.token,
      refetchMigrationSet,
      onCancel,
    ]
  );
};
